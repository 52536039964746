<template>
  <div class="main">
    <div class="banner">
      <img src="@/static/lanzhou/index/banner_project.png" alt="">
    </div>
    <div class="content">
      <ul class="ul">
        <li class="li" v-for="item in list" @click="getDetail(item)">
          <div class="title">
            <h3 class="line_clamp1">{{ item.title }}</h3>
            <img src="@/static/lanzhou/index/right.png" alt="">
          </div>
          <div class="box">
            <h4>培训内容、培训要求及目的</h4>
            <div class="text line_clamp2">
              {{ item.content }}
            </div>
          </div>
          <div class="box">
            <h4>就业方向</h4>
            <div class="text line_clamp1">
              {{ item.jobSouse }}
            </div>
          </div>
        </li>
      </ul>
      <div class="pages">
        <el-pagination
            background
            class="work_pagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="params.pageNum"
            :page-size="params.pageSize"
            layout="prev, pager, next, total, jumper"
            :total="params.total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
        :visible.sync="isShow"
        width="996px"
        top="10%"
        :before-close="handleClose"
    >
      <div class="el-dialog_content" v-if="detail">
        <h3>{{ detail.title }}</h3>
        <div class="box">
          <h4>培训内容、培训要求及目的</h4>
          <div class="text">
            {{ detail.content }}
          </div>
        </div>
        <div class="box">
          <h4>就业方向</h4>
          <div class="text">
            {{ detail.jobSouse }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      list: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      isShow: false,
      detail: null
    }
  },
  methods: {
    info() {
      this.$api.getLzTrNoticeListApi(this.params).then(res => {
        this.list = res.data.data;
      })
    },
    //分页
    handleCurrentChange: function (val) {
      this.params.pageNum = val;
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
    },
    handleClose() {
      this.isShow = false;
    },
    getDetail(data) {
      this.detail = data;
      this.isShow = true;
    },
  },
  created() {
    this.info();
  }
}
</script>

<style scoped lang="scss">
.main {
  .banner {
    img {
      width: 100%;
    }
  }

  .content {
    .ul {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;

      .li {
        width: 588px;
        height: 246px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-top: 24px;
        margin-right: 24px;
        cursor: pointer;

        &:hover {
          .title {
            h3 {
              color: #0a924c;
            }
          }
        }

        &:nth-of-type(2n) {
          margin-right: 0;
        }

        padding: 18px;

        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h3 {
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 20px;
            color: #333333;
            letter-spacing: 0.67px;
            line-height: 30px;
          }

          .right {
            margin-left: 40px;
          }
        }

        .box {
          margin-top: 18px;

          h3 {
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0.53px;
            line-height: 24px;
          }

          .text {
            margin-top: 6px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
            letter-spacing: 0.53px;
            text-align: justify;
            line-height: 24px;
          }
        }
      }
    }
  }
}

::v-deep .el-dialog {
  .el-dialog__header{
    .el-dialog__headerbtn{
      i{
        font-size:24px;
      }
    }
  }
  .el-dialog__body {
    padding-top: 0;
    margin-top: -20px;

    .el-dialog_content {
      h3 {
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 24px;
        color: #333333;
        letter-spacing: 0.8px;
        line-height: 40px;
      }
      .box{
        margin-top: 24px;
        h4{
          font-family: PingFangSC-Medium;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          letter-spacing: 0.53px;
          line-height: 24px;
        }
        .text{
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          letter-spacing: 0.53px;
          text-align: justify;
          line-height: 24px;
          margin-top: 6px;
        }
      }
    }
  }
}
</style>
